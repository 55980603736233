<!--商户分析-->
<template>
    <div class="warp">
<!--      <el-row>-->
<!--        <el-col :span="5">-->
<!--         <organization-tree ref="organizationTreeRef"></organization-tree>-->
<!--        </el-col>-->
<!--        <el-col :span="19">-->
          <el-card class="warp-content">
            <!-- 按钮操作区域 -->
            <div class="page-select">
              <el-row style="width: 100%; margin: 0 0 15px 0">
                <el-col :span="24" style="text-align: right">
                  <el-select size="small" v-model="selectValue" filterable placeholder="请选择商户">
                    <el-option
                        v-for="item in selectOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value" />
                  </el-select>
                  <el-form
                      label-width="100px"
                      :model="formData"
                      :rules="rules"
                      ref="ruleForm"
                      class="formClass"
                  >
                    <el-form-item label="时间选择：" prop="alarTime">
                      <el-date-picker
                          v-model="formData.alarTime"
                          unlink-panels
                          type="datetimerange"
                          size="small"
                          range-separator="至"
                          start-placeholder="起始时间"
                          end-placeholder="结束时间"
                          format="YYYY-MM-DD HH:mm:ss"
                          value-format="YYYY-MM-DD HH:mm:ss"
                          style="margin-top: 6px;"
                          :shortcuts="datePickerShortcut"
                      />

                    </el-form-item>
                  </el-form>
                  <el-button type="primary" size="small" @click="searchClick">搜索 </el-button>
                </el-col>
              </el-row>
            </div>
            <!-- 表格区域 -->
            <div class="page-container">
              <el-table
                  ref="tableRef"
                  :data="tableData"
                  style="width: 100%"
                  max-height="630px"
                  :header-cell-style="{ background: '#F2F4F8' }"
                  @selection-change="handleSelectionChange"
              >
                <el-table-column type="selection" align="center" width="80" />
                <el-table-column v-for="(item, index) in tableColumnData" :key="index" :prop="item.prop" :label="item.label" align="center"></el-table-column>
                <el-table-column label="操作" align="center">
                  <template #default="{row}">
                    <el-button type="text" @click="editClick(row)">日统计</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <!-- 分页区域 -->
            <div class="page-foot">
              <page-nation
                  :total="total"
                  @pageChange="handleSizeChange"
                  @currentChange="handleCurrentChange"
              ></page-nation>
            </div>
          </el-card>
<!--        </el-col>-->
<!--      </el-row>-->

    </div>
  </template>

  <script>
  import { reactive, toRefs, onMounted, getCurrentInstance } from "vue-demi";
  import pageNation from "@/components/base/pageNation.vue";
  import { requestApi } from "@/utils/requestApi";
  import { debounces } from "@/common/utils";
  import datePickerComposition from "../../../common/datePickerComposition";
  import {merchantPagesApi} from "@/api/synthesisAnalysis/deviceAnalysis";
  export default {
    name: "MerchantAnalysis",
    components: {pageNation },
    setup() {
      const { proxy } = getCurrentInstance();
      const { datePickerShortcut } = datePickerComposition()
      const data = reactive({
        selectValue: null, //搜索内容（用户名）
        selectOptions:[
          { value:0, label:'尖岗水库'}
        ],
        searchContent: null, //搜索日志内容
        tableData: [{MerchantName: '尖岗水库', userName: '45'}], // 表格数据
        total: 0,
        pageSize: 10,
        currentPage: 1,
        userInfo: null,
        selection: [],
        disabled: true,
        formData: {
          alarTime: [], //起始时间
        },
        tableColumnData: [{
          prop: 'merchantName',
          label: '商户名称'
        }, {
          prop: 'userName',
          label: '总金额(元)'
        }, {
          prop: 'userName',
          label: '总电度数(度)'
        }, {
          prop: 'userName',
          label: '总时长(小时)'
        }, {
          prop: 'userName',
          label: '订单数(次)'
        }, {
          prop: 'userName',
          label: '最大金额(元)'
        }, {
          prop: 'userName',
          label: '最大时长(小时)'
        }],

      });
      onMounted(() => {
        proxy.userInfo = JSON.parse(localStorage.getItem("userInfo"));
      });
      // 节流搜索按钮
      const searchInput = debounces(() => {
      }, 500);


      const methods = {
        merchantPages() {
          requestApi(()=> {
            return merchantPagesApi({
              current: data.currentPage,
              size: data.pageSize
            },(res) => {
              data.tableData = res.data.records
              proxy.total = res.data.total
              proxy.currentPage = res.data.current
              proxy.pageSize = res.data.size
            })
          })
        },
        editClick(row) {
          console.log(row);
          },

      };
      return {
        ...toRefs(data),
        searchInput,
        ...methods,
        datePickerShortcut
      };
    },
  };
  </script>
  <style lang="less" scoped>


  .warp {
    height: 100%;
    .warp-content {
      height: 100%;
    }
  }
  .page-select {
    width: 100%;
    margin: 0 auto;
    .formClass {
      display: inline-block;
      margin-right: 4px;
    }
  }
  .page-container {
    width: 100%;
    margin-top: -28px;
  }
  .page-foot {
    width: 100%;
    margin-top: 14px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .my-btn {
      height: 28px;
    }
  }
  </style>
