export default function datePickerComposition() {
    const datePickerShortcut = [
        {
            text: '今天',
            value: () => {
                const end = new Date()
                const start = new Date(new Date().setHours(0, 0, 0, 0))
                return [start, end]
            },
        },
        {
            text: '近7天',
            value: () => {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                return [start, end]
            },
        },
        {
            text: '近30天',
            value: () => {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                return [start, end]
            },
        },
        {
            text: '今年',
            value: () => {
                const end = new Date()
                var date = new Date();
                var year1 = date.getFullYear();
                var start = year1 + '-' + '01' + '-' + '01'  + " " + "00:00:00";
                return [start, end]
            },
        }
    ]

    return{
        datePickerShortcut
    }
}
